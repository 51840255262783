import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../style.css';
import './head.css';
import IcoLogin from '../../img/ico/ico-loginUser.svg';
import Login from '../Login';
import { validateAuth, logout } from '../../components/Connector';

function Head() {
    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
    const [loginText, setLoginText] = useState('Acessar');
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuth = async () => {
            const isAuthenticated = await validateAuth();
            if (isAuthenticated) {
                setLoginText('Sair');
                setIsLoginModalVisible(false);
            } else {
                setLoginText('Acessar');
                navigate('/home');
                setIsLoginModalVisible(true);
            }
        };

        checkAuth();
    }, [navigate]);

    const handleLoginSuccess = () => {
        setIsLoginModalVisible(false);
        setLoginText('Sair');
        console.log('Usuário conectado');
    };

    const handleLogout = () => {
        logout();
        setLoginText('Acessar');
        console.log('Usuário desconectado');
        navigate('/home');
    };

    const handleLoginClick = () => {
        if (loginText === 'Sair') {
            handleLogout();
        } else {
            setIsLoginModalVisible(true);
        }
    };

    return (
        <section className='header-container'>
            <div className='header'>
                <h1>Módulo Administrativo - IDE SISEMA</h1>
                <div className='btn-login' onClick={handleLoginClick}>
                    <img src={IcoLogin} className='ico-login' alt='login' />
                    <p>{loginText}</p>
                </div>
            </div>
            <Login
                isVisible={isLoginModalVisible}
                onClose={() => navigate('/home')}
                onLoginSuccess={handleLoginSuccess}
                onLogout={handleLogout}
            />
        </section>
    );
}

export default Head;