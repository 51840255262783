const suffixLinkWMSGetCapabilities = '?request=GetCapabilities&service=WMS&AcceptFormats=text/xml&';
const suffixLinkWMSDescribeLayer = '?service=WMS&version=1.1.1&request=DescribeLayer&output_format=application/json';
const suffixLinkWFSDescribeFeatureType = "&request=describeFeatureType&outputFormat=application/json";
const suffixLinkWFSGetCapabilities = "&request=GetCapabilities&version=2.0.0";
const suffixLinkWFSGetFeatures = "&request=GetFeature&version=2.0.0&outputFormat=";
const excludeFormats = [
    "gml",
    "csv",
    "excel",
    "application"
];

async function wmsCapabilities(wmsUrl) {
    try {
        const fullUrl = `${wmsUrl}${suffixLinkWMSGetCapabilities}`;
        const response = await fetch(fullUrl, {
            headers: {
                Accept: 'application/xml',
            },
        });

        if (!response.ok) {
            throw new Error(`Erro na requisição: ${response.status} ${response.statusText}`);
        }

        const xmlText = await response.text();
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlText, 'application/xml');

        if (xmlDoc.querySelector('parsererror')) {
            throw new Error('Erro ao parsear XML.');
        }

        const title = xmlDoc.querySelector('Service > Title')?.textContent || '';
        const abstract = xmlDoc.querySelector('Service > Abstract')?.textContent || '';
        const person = xmlDoc.querySelector('Service > ContactInformation > ContactPersonPrimary > ContactPerson')?.textContent || '';
        const organization = xmlDoc.querySelector('Service > ContactInformation > ContactPersonPrimary > ContactOrganization')?.textContent || '';
        const position = xmlDoc.querySelector('Service > ContactInformation > ContactPosition')?.textContent || '';
        const webMapLayer = xmlDoc.querySelector('Layer');
        if (!webMapLayer) {
            throw new Error('Nenhuma camada encontrada no XML.');
        }

        const layers = Array.from(webMapLayer.querySelectorAll('Layer')).map((layer) => {
            const name = layer.querySelector('Name')?.textContent || '';
            const title = layer.querySelector('Title')?.textContent || '';
            const styles = Array.from(layer.querySelectorAll('Style')).map((style) => {
                return {
                    name: style.querySelector('Name')?.textContent || '',
                    title: style.querySelector('Title')?.textContent || '',
                };
            });

            return {
                name,
                title,
                styles,
            };
        });

        return {
            title,
            abstract,
            person,
            organization,
            position,
            layers,
        };
    } catch (error) {
        throw new Error(`Erro ao buscar WMS: ${error.message}`);
    }
}

async function getWFSURL(wfsurl,layername) {
    const fullUrl = `${wfsurl}${suffixLinkWMSDescribeLayer}&layers=${layername}`;
    const response = await fetch(fullUrl, {
        headers: {
            Accept: 'application/json',
        },
    });
    if (!response.ok)
        return null;
    const json = await response.json();
    if (json["layerDescriptions"])
        for (let desc of json["layerDescriptions"])
            if (desc["owsType"] == "WFS")
                return desc["owsURL"]+"service=WFS&version=2.0.0&typeNames="+desc["typeName"];
    return null;
}

async function wfsGetFields(wfsurl) {
    const fullUrl = `${wfsurl}${suffixLinkWFSDescribeFeatureType}`;
    const response = await fetch(fullUrl, {
        headers: {
            Accept: 'application/json',
        },
    });
    if (!response.ok)
        return [];
    const json = await response.json();
    const fieldlist = [];
    if (json["featureTypes"])
        for (let prop of json["featureTypes"][0]["properties"]) {
            if (prop["type"].toLowerCase().includes("polygon") || prop["type"].toLowerCase().includes("point") || prop["type"].toLowerCase().includes("linestring"))
                continue;
            fieldlist.push({
                name: prop["name"],
                type: prop["localType"]
            })
        }
    return fieldlist
}

async function wfsGetDownloadURLs(wfsurl) {
    const fullUrl = `${wfsurl}${suffixLinkWFSGetCapabilities}`;
    const response = await fetch(fullUrl, {
        headers: {
            Accept: 'application/json',
        },
    });
    if (!response.ok)
        return [];
    const xmlText = await response.text();
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlText, 'application/xml');

    const outputFormats = xmlDoc.querySelectorAll("Parameter[name='outputFormat'] > AllowedValues > Value");
    let formats = Array.from(outputFormats).map((format) => format.textContent);
    formats = formats.filter((f) => {
        f = f.toLowerCase();
        for (let exclude of excludeFormats)
            if (f.includes(exclude) && !f.includes("excel2007"))
                return false;
        return true;
    });
    return formats.map((f) => {return {type: f, url: `${wfsurl}${suffixLinkWFSGetFeatures}${f}`}})
}

export {
    getWFSURL,
    wmsCapabilities,
    wfsGetFields,
    wfsGetDownloadURLs
}