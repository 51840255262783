import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './pages/Home';
import CarCamada from './pages/CarCamada';
import CadMapa from './pages/CadMapa';
import ConfigCamada from './pages/ConfigCamada';
import ConfigService from './pages/ConfigService';
import GerUsuario from './pages/GerUsuario';
import LogAcesso from './pages/LogAcesso';
import Erro from './pages/Erro';

function RoutesApp() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={ <Home/> } />
                <Route path="/home" element={ <Home/> } />
                <Route path="/carCamada" element={ <CarCamada/> } />
                <Route path="/cadMapa" element={ <CadMapa/> } />
                <Route path="/configCamada" element={ <ConfigCamada/> } />
                <Route path="/configService" element={ <ConfigService/> } />
                <Route path="/gerUsuario" element={ <GerUsuario/> } />
                <Route path="/logAcesso" element={ <LogAcesso/> } />
                <Route path='*' element={ <Erro />} />
            </Routes>
        </BrowserRouter>
    );
}

export default RoutesApp;