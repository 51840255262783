import { Link } from 'react-router-dom';
import './foot.css';
import logo from '../../img/logo/logo-sisema.png';
import logoFeam from '../../img/logo/logo-feam.svg';
import logoGovMG from '../../img/logo/logo-gov-mg.svg';
import logoIef from '../../img/logo/logo-ief.svg';
import logoIgam from '../../img/logo/logo-igam.svg';

function Foot() {
    let barra = (
        <div className='bar'>
            &nbsp;
        </div>
    )

    let logoSisema = (
        <div className='logo-sisema'>
            <Link to="/"><img src={logo} className='logo-sisema' alt='IEDE SISEMA' /></Link>
        </div>
    )

    let logos = (
        <div className='logos'>
            {logoSisema}
            <div className="logos-institucional">
                <a href="." target="_blank" rel="noreferrer"><img src={logoGovMG} className='logos-in' alt='Governo Estadual de Minas Gerais' /></a>
                <a href="." target="_blank" rel="noreferrer"><img src={logoFeam} className='logos-in' alt='Fundação Estadual do Meio Ambiente' /></a>
                <a href="." target="_blank" rel="noreferrer"><img src={logoIef} className='logos-in' alt='Instituto Estadual de Florestas' /></a>
                <a href="." target="_blank" rel="noreferrer"><img src={logoIgam} className='logos-in' alt='Instituto Mineiro de Gestão das Águas' /></a>
            </div>
        </div>
    );

    let elemento = (
        <div className='footer'>
            {barra}
            {logos}
        </div>
    );

    return (
        <section className='footer-container'>
            {elemento}
        </section>
    )
}

export default Foot;