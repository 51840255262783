import { useState } from 'react';
import '../../components/style.css';
import Head from '../../components/Head';
import LateralBar from '../../components/LateralBar';
import { TBCarCamada } from '../../components/TopBar';
import { IcoAddWorkorderSmall } from '../../img/ico/icoBtn';

function CarCamada() {
    const [file, setFile] = useState(null);
    const [additionalStyles, setAdditionalStyles] = useState([]);
    const [newStyle, setNewStyle] = useState('');

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
        } else {
            alert('Selecione um arquivo válido.');
        }
    };

    const handleAddStyle = () => {
        if (newStyle && !additionalStyles.includes(newStyle)) {
            setAdditionalStyles([...additionalStyles, newStyle]);
            setNewStyle('');
        } else if (newStyle) {
            alert('Este estilo já foi adicionado.');
        } else {
            alert('Selecione um estilo para adicionar.');
        }
    };

    const handleRemoveStyle = (style) => {
        setAdditionalStyles(additionalStyles.filter((s) => s !== style));
    };

    return (
        <section className="carCamada">
            <Head />
            <div className="main-container">
                <LateralBar />
                <div className='right-container'>
                    <TBCarCamada />
                    <div className="form-container">
                        <div className='formStep'>
                            <h2>Passo 1 - Carregue seu arquivo</h2>
                            <div className="upload-container" onClick={() => document.getElementById('fileInput').click()}>
                                <input
                                    type="file"
                                    id="fileInput"
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                />
                                <IcoAddWorkorderSmall />
                                <div className="upload-placeholder">
                                    {file ? file.name : "Clique aqui ou arraste seu arquivo para essa área"}
                                </div>
                            </div>

                            <div className='input-group'>
                                <label>Codificação</label>
                                <select>
                                    <option value="UTF-8">UTF-8</option>
                                    <option value="GB2312">GB2312</option>
                                    <option value="GBK">GBK</option>
                                    <option value="ISO-8859-1">ISO-8859-1</option>
                                    <option value="ShiftJIS">Shift JIS</option>
                                    <option value="Windows-1251">Windows-1251</option>
                                    <option value="Windows-1252">Windows-1252</option>
                                </select>
                            </div>
                        </div>

                        <div className='formStep'>
                            <h2>Passo 2 - Configurações da Tabela no Banco de Dados</h2>
                            <div className='input-group'>
                                <label>Nome da Tabela</label>
                                <input type="text" defaultValue="unidades_de_convervacacao_estaduais" />
                            </div>
                            <div className='input-group'>
                                <label>Esquema</label>
                                <select>
                                    <option value="meio_ambiente">meio_ambiente</option>
                                    <option value="infraestrutura">infraestrutura</option>
                                    <option value="hidrografia">hidrografia</option>
                                </select>
                            </div>
                            <div className='input-group'>
                                <label className='up-label'>Opções</label>
                                <div className="checkbox-container">
                                    <label>
                                        <input type="checkbox" defaultChecked />
                                        Sobreescrever a tabela se existir
                                    </label>
                                    <label>
                                        <input type="checkbox" defaultChecked />
                                        Converter nome dos campos para minúscula
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className='formStep'>
                            <h2>Passo 3 - Configurações da Camada no Geoserver</h2>
                            <div className='input-group'>
                                <label>Nome da Camada</label>
                                <input type="text" defaultValue="unidades_de_convervacacao_estaduais" />
                            </div>
                            <div className='input-group'>
                                <label>Título</label>
                                <input type="text" defaultValue="Unidades de Conservação Estaduais" />
                            </div>
                            <div className='input-group'>
                                <label>Workspace</label>
                                <select>
                                    <option value="ide_sisema">ide_sisema</option>
                                </select>
                            </div>
                            <div className='input-group'>
                                <label>Habilitar Cache</label>
                                <input type="checkbox" defaultChecked />
                            </div>
                            <div className='input-group'>
                                <label>Estilo Padrão</label>
                                <select>
                                    <option value="uc_por_tipo">uc_por_tipo</option>
                                </select>
                            </div>
                            <div className='input-group'>
                                <label>Estilos Adicionais</label>
                                <div className="tag-input-container">
                                    <select value={newStyle} onChange={(e) => setNewStyle(e.target.value)}>
                                        <option value="">Selecionar estilo</option>
                                        <option value="uc_transparente">uc_transparente</option>
                                        <option value="uc_verde">uc_verde</option>
                                        <option value="uc_por_ano">uc_por_ano</option>
                                    </select>
                                    <button onClick={handleAddStyle} className='add-btn'>+</button>
                                </div>
                            </div>
                            <div className="tags-container">
                                {additionalStyles.map((style) => (
                                    <div className="tag" key={style}>
                                        {style} <button onClick={() => handleRemoveStyle(style)}>&#x2715;</button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CarCamada;