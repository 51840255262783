import React from 'react';

const IcoCadMapaSmall = ({ color }) => (
    <svg width="60" height="59.364" viewBox="0 0 60 60">
        <path d="M3.309,6.192C2,7.682,2,10.236,2,15.345v28.18c0,3.618,0,5.427.944,6.789s2.594,1.934,5.894,3.078l3.883,1.346c2.1.729,3.721,1.29,5.079,1.651a1.371,1.371,0,0,0,1.7-1.376V9.562A1.648,1.648,0,0,0,18.225,8c-1.168-.3-2.571-.783-4.5-1.45-4.66-1.615-6.99-2.423-8.762-1.6A5.011,5.011,0,0,0,3.309,6.192Z" transform="translate(0 5.035)" fill={color} />
        <path d="M18.861,5.233,14.253,8.428c-1.667,1.156-2.889,2-3.932,2.607A1.606,1.606,0,0,0,9.5,12.41V57.551a1.36,1.36,0,0,0,2.089,1.207c1.005-.643,2.156-1.441,3.55-2.408l4.608-3.195c1.667-1.156,2.889-2,3.932-2.607a1.605,1.605,0,0,0,.821-1.375V4.032a1.36,1.36,0,0,0-2.089-1.207C21.406,3.468,20.255,4.267,18.861,5.233Z" transform="translate(15 0.997)" fill={color} />
        <path d="M26.829,5.15,22.946,3.8c-2.1-.729-3.721-1.29-5.079-1.651a1.371,1.371,0,0,0-1.7,1.376V48.98a1.648,1.648,0,0,0,1.275,1.561c1.168.3,2.572.783,4.5,1.45,4.66,1.615,6.99,2.423,8.762,1.6a5.009,5.009,0,0,0,1.659-1.244c1.309-1.49,1.309-4.044,1.309-9.152V15.017c0-3.618,0-5.427-.944-6.789S30.129,6.294,26.829,5.15Z" transform="translate(28.333 0)" fill={color} />
    </svg>
);

const IcoCarCamadaSmall = ({ color }) => (
    <svg width="55" height="55" viewBox="0 0 60 60">
        <path d="M59,25.046c0,6.075-12.312,11-27.5,11S4,31.121,4,25.046V13.974a21.119,21.119,0,0,0,6.9,4.194c5.4,2.162,12.691,3.44,20.6,3.44s15.194-1.278,20.6-3.44A21.118,21.118,0,0,0,59,13.974Z" transform="translate(0 20.954)" fill={color} />
        <path d="M31.5,15.608c7.907,0,15.194-1.278,20.6-3.44A21.122,21.122,0,0,0,59,7.974V19.046c0,1.375-6.138,4.376-9.208,5.933a51.122,51.122,0,0,1-18.292,3,51.124,51.124,0,0,1-18.292-3C9.156,23.358,4,20.421,4,19.046V7.974a21.123,21.123,0,0,0,6.9,4.195C16.306,14.33,23.593,15.608,31.5,15.608Z" transform="translate(0 10.454)" fill={color} />
        <path d="M49.718,18.933a51.125,51.125,0,0,1-18.292,3,51.127,51.127,0,0,1-18.292-3,22.646,22.646,0,0,1-8.515-5.165,1.913,1.913,0,0,1-.556-1.709,4.215,4.215,0,0,1,.33-1.088C6.773,5.865,17.971,2,31.426,2s24.653,3.865,27.033,8.971a4.205,4.205,0,0,1,.33,1.088,1.913,1.913,0,0,1-.556,1.709A22.645,22.645,0,0,1,49.718,18.933Z" transform="translate(0.074)" fill={color} />
    </svg>
);

const IcoConfigCamadaSmall = ({ color }) => (
    <svg width="60" height="60" viewBox="0 0 60 60">
        <path d="M30.913,0l-30,14.055,30,14.141,30-14.141ZM11.26,14.066,30.913,4.858l19.655,9.208L30.913,23.33Z" transform="translate(0)" fill={color} />
        <path d="M30.914,29.508,10.686,19.974.914,24.552l30,14.14,30-14.14-9.771-4.578Z" transform="translate(0 1.12)" fill={color} />
        <path d="M30.914,40.508,10.686,30.974.914,35.552l30,14.14,30-14.14-9.771-4.578Z" transform="translate(0 1.737)" fill={color} />
    </svg>
);

const IcoConfigServiceSmall = ({ color }) => (
    <svg width="57.55" height="60" viewBox="0 0 60 60">
        <path d="M3.189,6.048C2,7.4,2,9.721,2,14.361V39.954c0,3.286,0,4.929.857,6.166s2.356,1.756,5.353,2.8l3.526,1.222c1.91.662,3.379,1.172,4.612,1.5a1.245,1.245,0,0,0,1.545-1.25V9.109a1.5,1.5,0,0,0-1.158-1.417c-1.06-.269-2.335-.711-4.082-1.317C8.421,4.908,6.305,4.174,4.7,4.919A4.551,4.551,0,0,0,3.189,6.048Z" transform="translate(0 4.248)" fill="none" stroke={color} strokeWidth="4" />
        <path d="M18,4.992l-4.185,2.9c-1.514,1.05-2.624,1.819-3.571,2.368A1.458,1.458,0,0,0,9.5,11.51v41a1.235,1.235,0,0,0,1.9,1.1c.913-.584,1.958-1.309,3.224-2.187l4.185-2.9c1.514-1.05,2.624-1.819,3.571-2.367a1.458,1.458,0,0,0,.746-1.249V3.9a1.235,1.235,0,0,0-1.9-1.1C20.313,3.389,19.268,4.114,18,4.992Z" transform="translate(12.934 0.766)" fill="none" stroke={color} strokeWidth="4" />
        <path d="M25.85,4.871,22.324,3.648c-1.91-.662-3.379-1.172-4.612-1.5A1.245,1.245,0,0,0,16.167,3.4V44.677a1.5,1.5,0,0,0,1.158,1.417c1.061.269,2.336.711,4.083,1.317,4.232,1.467,6.348,2.2,7.958,1.456a4.55,4.55,0,0,0,1.506-1.13c1.189-1.353,1.189-3.673,1.189-8.312V13.832c0-3.286,0-4.929-.857-6.166S28.847,5.91,25.85,4.871Z" transform="translate(24.432 -0.094)" fill="none" stroke={color} strokeWidth="4" />
        <path d="M17.392,0A17.392,17.392,0,1,1,0,17.392,17.392,17.392,0,0,1,17.392,0Z" transform="translate(27.217 29.858)" fill="#f2fff9" />
        <path d="M29.953,18.6h.482V12H26.123a11.353,11.353,0,0,0-.916-2.225l2.7-2.7.341-.341-.341-.341-3.98-3.98-.341-.341-.341.341L20.535,5.127a11.45,11.45,0,0,0-2.1-.87V0H11.841V4.257a11.483,11.483,0,0,0-2,.814L7.186,2.412l-.341-.341L6.5,2.412l-3.98,3.98-.341.341.341.341,2.6,2.6A11.346,11.346,0,0,0,4.2,11.843H0v6.593H4.152a11.358,11.358,0,0,0,.973,2.328l-2.6,2.6-.341.341.341.341,3.98,3.98.341.341.341-.341,2.658-2.658a11.33,11.33,0,0,0,2.158.86v4.209h6.593v-4.3a11.383,11.383,0,0,0,1.941-.82l2.714,2.714.341.341.341-.341,3.98-3.98.341-.341-.341-.341-2.7-2.7a11.382,11.382,0,0,0,.869-2.064h3.877ZM21.972,15.22a6.834,6.834,0,1,1-6.834-6.834A6.842,6.842,0,0,1,21.972,15.22Z" transform="translate(29.826 32.267)" fill={color} />
    </svg>
);

const IcoGerUsuarioSmall = ({ color }) => (
    <svg width="60" height="60" viewBox="0 0 60 60">
        <path d="M5,18.514A13.514,13.514,0,1,1,18.514,32.027,13.514,13.514,0,0,1,5,18.514Z" transform="translate(3.99 -5)" fill={color} fillRule="evenodd" />
        <path d="M14.416,24.208a.592.592,0,0,0,.156.756,10.511,10.511,0,1,0,0-16.906.592.592,0,0,0-.156.756,16.444,16.444,0,0,1,1.9,7.7A16.444,16.444,0,0,1,14.416,24.208Z" transform="translate(22.705 -2.997)" fill={color} />
        <path d="M9.919,17.1c3.7-1.606,8.557-2.1,14.591-2.1s10.9.495,14.6,2.1a13.391,13.391,0,0,1,7.769,8.957,3.061,3.061,0,0,1-2.929,3.953H5.074a3.066,3.066,0,0,1-2.932-3.96A13.391,13.391,0,0,1,9.919,17.1Z" transform="translate(-2.006 15.03)" fill={color} fillRule="evenodd" />
        <path d="M15.475,14.111c-1.243.077-1.316,1.747-.174,2.244h0a16.028,16.028,0,0,1,7.178,5.923,7.541,7.541,0,0,0,6.057,3.736h5.347a3.112,3.112,0,0,0,2.926-4.172q-.064-.178-.134-.353a10.245,10.245,0,0,0-5.4-5.726A24.043,24.043,0,0,0,22.144,14l-.059,0h-.059C19.9,14,17.673,13.974,15.475,14.111Z" transform="translate(23.001 13.026)" fill={color} />
    </svg>
);

const IcoLogAcessoSmall = ({ color }) => (
    <svg width="60" height="60" viewBox="0 0 60 60">
        <path d="M156.845,129.55h5.583a1.923,1.923,0,0,0,1.923-1.923V109.776a1.923,1.923,0,0,0-1.923-1.923h-5.583a1.923,1.923,0,0,0-1.923,1.923v17.852A1.923,1.923,0,0,0,156.845,129.55Z" transform="translate(-123.564 -86.022)" fill={color} />
        <path d="M86.923,151.92h5.583A1.923,1.923,0,0,0,94.429,150V137.822a1.923,1.923,0,0,0-1.923-1.923H86.923A1.923,1.923,0,0,0,85,137.822V150A1.923,1.923,0,0,0,86.923,151.92Z" transform="translate(-67.795 -108.392)" fill={color} />
        <path d="M226.767,184.584h5.583a1.923,1.923,0,0,0,1.923-1.923v-3.885a1.923,1.923,0,0,0-1.923-1.923h-5.583a1.923,1.923,0,0,0-1.923,1.923v3.885A1.923,1.923,0,0,0,226.767,184.584Z" transform="translate(-179.333 -141.056)" fill={color} />
        <path d="M56.964,49.1H10.815V3.036a3.036,3.036,0,1,0-6.072,0V49.1H3.036a3.036,3.036,0,0,0,0,6.072H4.743v1.792a3.036,3.036,0,1,0,6.072,0V55.172H56.964a3.036,3.036,0,1,0,0-6.072Z" fill={color} />
        <path d="M86.923,79.509h5.583a1.923,1.923,0,0,0,1.923-1.923V72a1.923,1.923,0,0,0-1.923-1.923H86.923A1.923,1.923,0,0,0,85,72v5.583A1.923,1.923,0,0,0,86.923,79.509Z" transform="translate(-67.795 -55.895)" fill={color} />
        <path d="M156.845,51.009h5.583a1.923,1.923,0,0,0,1.923-1.923V43.5a1.923,1.923,0,0,0-1.923-1.923h-5.583a1.923,1.923,0,0,0-1.923,1.923v5.583A1.923,1.923,0,0,0,156.845,51.009Z" transform="translate(-123.564 -33.164)" fill={color} />
        <path d="M226.767,122.036h5.583a1.923,1.923,0,0,0,1.923-1.923V114.53a1.923,1.923,0,0,0-1.923-1.923h-5.583a1.923,1.923,0,0,0-1.923,1.923v5.583A1.923,1.923,0,0,0,226.767,122.036Z" transform="translate(-179.333 -89.814)" fill={color} />
    </svg>
);

export { IcoCadMapaSmall, IcoCarCamadaSmall, IcoConfigCamadaSmall, IcoConfigServiceSmall, IcoGerUsuarioSmall, IcoLogAcessoSmall };