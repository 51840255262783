import { Link } from 'react-router-dom';
import '../../components/style.css';
import Head from '../../components/Head';
import Foot from '../../components/Foot';
import IcoCadMapa from '../../img/ico/ico-cadMapa.svg'
import IcoCarCamada from '../../img/ico/ico-carCamada.svg'
import IcoConfigCamada from '../../img/ico/ico-configCamada.svg'
import IcoConfigService from '../../img/ico/ico-configService.svg'
import IcoGerUsuario from '../../img/ico/ico-gerUsuario.svg'
import IcoLogAcesso from '../../img/ico/ico-logAcesso.svg'

function Home() {
    let btnGrid = (
        <div className='grid-btn'>
            <Link to="/carCamada" className="square-btn">
                <img src={IcoCarCamada} alt="Icon" />
                <span>Carregar Nova Camada</span>
            </Link>
            <Link to="/cadMapa" className="square-btn">
                <img src={IcoCadMapa} alt="Icon" />
                <span>Cadastro de Mapa Base</span>
            </Link>
            <Link to="/configCamada" className="square-btn">
                <img src={IcoConfigCamada} alt="Icon" />
                <span>Configurar Camadas</span>
            </Link>
            <Link to="/gerUsuario" className="square-btn">
                <img src={IcoGerUsuario} alt="Icon" />
                <span>Gerenciar Usuários</span>
            </Link>
            <Link to="/configService" className="square-btn">
                <img src={IcoConfigService} alt="Icon" />
                <span>Configurar Serviços</span>
            </Link>
            <Link to="/logAcesso" className="square-btn">
                <img src={IcoLogAcesso} alt="Icon" />
                <span>Logs e Acessos</span>
            </Link>
        </div>
    );

    return (
        <section className="home">
            <Head />
            <div className="main-container">
                {btnGrid}
            </div>
            <Foot />
        </section>
    );
}

export default Home;