import { useState, useEffect, useCallback, useRef } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import '../../components/style.css';
import Head from '../../components/Head';
import LateralBar from '../../components/LateralBar';
import { TBLayers } from '../../components/TopBar';
import Grid from '../../components/Grid';
import { fetchLayersPaginated, fetchLayerSources, fetchLayerGroups } from '../../components/Connector';
import { FormTabLayers } from '../../components/FormTab';

function ConfigCamada() {
    const tab1 = 'Lista de Camadas';
    const [tabs, setTabs] = useState([{ id: 1, title: tab1, formData: {}, initialID: null, showSecondaryButtons: false }]);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [layersGridData, setLayersGridData] = useState([]);
    const [filterLayers, setFilterLayers] = useState("");
    const [layersData, setLayersData] = useState([]);
    const [existingGroups, setExistingGroups] = useState([]);
    const [sources, setSources] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const cntNetLayer = useRef(0);

    const loadLayers = useCallback(async () => {
        setIsLoading(true);
        try {
            const { gridData, fullData, pagination } = await fetchLayersPaginated(currentPage, pageSize, filterLayers);
            const sources = await fetchLayerSources();
            const groups = await fetchLayerGroups();
            setLayersGridData(gridData);
            setLayersData(fullData);
            setExistingGroups(groups);
            setSources(sources);
            setTotalItems(pagination.total)
            setTotalPages(pagination.last_page);
            /*if (pagination.total < pageSize) {
                setPageSize(pagination.total);
            }*/
        } catch (error) {
            console.error('Falha ao buscar dados de Camadas', error);
        } finally {
            setIsLoading(false);
        }
    }, [currentPage, pageSize, filterLayers]);

    useEffect(() => {
        loadLayers();
    }, [currentPage, pageSize, loadLayers]);

    const onPageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const onPageSizeChange = (newSize) => {
        setPageSize(newSize);
        setCurrentPage(1);
    };

    const addTab = () => {
        cntNetLayer.current += 1;
        setTabs([...tabs, { id: 99999+cntNetLayer.current, title: `Nova Camada #${cntNetLayer.current}`, formData: {}, showSecondaryButtons: true }]);
        setSelectedTabIndex(tabs.length);
    };

    const removeTab = (index) => {
        const updatedTabs = tabs.filter((_, tabIndex) => tabIndex !== index);
        setTabs(updatedTabs);
        setSelectedTabIndex(index === selectedTabIndex ? 0 : selectedTabIndex > index ? selectedTabIndex - 1 : selectedTabIndex);
    };

    const editRow = (row) => {
        const layerDetails = layersData.find(item => item.id === row.ID);
        setTabs([...tabs, { id: tabs.length + 1, title: `Editar Camada #${row.ID}`, formData: layerDetails, initialID: row.ID, showSecondaryButtons: true }]);
        setSelectedTabIndex(tabs.length);
    };

    const handleTabChange = (index, updatedFormData) => {
        const updatedTabs = [...tabs];
        updatedTabs[index] = { ...updatedTabs[index], formData: updatedFormData };
        setTabs(updatedTabs);
    };

    const headers = layersGridData.length > 0
        ? [...Object.keys(layersGridData[0]).filter(header => header !== 'ZoomMin' && header !== 'ZoomMax' && header !== 'Atribuições'), 'Ações']
        : ['Ações'];

    const refreshData = () => {
        loadLayers();
    };

    return (
        <section className="configCamada">
            <Head />
            <div className="main-container">
                <LateralBar />
                <div className='right-container'>
                    <TBLayers
                        addTab={addTab}
                        showSecondaryButtons={tabs[selectedTabIndex]?.showSecondaryButtons}
                        currentPage={currentPage}
                        totalItems={totalItems}
                        pageSize={pageSize}
                        onPageChange={onPageChange}
                        onPageSizeChange={onPageSizeChange}
                        onRefresh={refreshData}
                        filter={filterLayers}
                        setFilter={setFilterLayers}
                        initialID={tabs[selectedTabIndex]?.initialID}
                        removeTab={removeTab}
                        selectedTabIndex={selectedTabIndex}
                        formData={tabs[selectedTabIndex]?.formData}
                    />
                    <Tabs selectedIndex={selectedTabIndex} onSelect={index => setSelectedTabIndex(index)}>
                        <TabList>
                            {tabs.map((tab, index) => (
                                <Tab key={index}>
                                    {tab.title}
                                    {index !== 0 && <button onClick={(e) => { e.stopPropagation(); removeTab(index); }}>&#x2715;</button>}
                                </Tab>
                            ))}
                        </TabList>
                        {tabs.map((tab, index) => (
                            <TabPanel key={index}>
                                <div className="tab-content">
                                    {tab.title === tab1 ? (
                                        <Grid headers={headers} data={layersGridData} area="ConfigCamada" editRow={editRow} isLoading={isLoading} loadData={loadLayers} />
                                    ) : (
                                        <FormTabLayers formData={tab.formData} initialID={tab.initialID} sources={sources} existingGroups={existingGroups} onChange={(updatedFormData) => handleTabChange(index, updatedFormData)} />
                                    )}
                                </div>
                            </TabPanel>
                        ))}
                    </Tabs>
                </div>
            </div>
        </section>
    );
}

export default ConfigCamada;