import React, { useState, useEffect, useCallback } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import '../../components/style.css';
import Head from '../../components/Head';
import LateralBar from '../../components/LateralBar';
import { TBUser, TBGroup } from '../../components/TopBar';
import Grid from '../../components/Grid';
import { fetchUsersPaginated, fetchGroupsPaginated } from '../../components/Connector';
import { FormTabUser, FormTabGroup } from '../../components/FormTab';

function GerUsuario() {
    const tab1 = 'Lista de Usuários';
    const tab2 = 'Lista de Grupos';

    const [tabs, setTabs] = useState([{ id: 1, title: tab1 }, { id: 2, title: tab2 }]);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [usersData, setUsersData] = useState([]);
    const [filteredUsersData, setFilteredUsersData] = useState([]);
    const [groupsGridData, setGroupsGridData] = useState([]);
    const [filteredGroupsGridData, setFilteredGroupsGridData] = useState([]);
    const [groupsFormData, setGroupsFormData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [totalItems, setTotalItems] = useState(0);

    const loadUsersGroups = useCallback(async () => {
        setIsLoading(true);
        if (selectedTabIndex === 0) {
            const { items, pagination } = await fetchUsersPaginated(currentPage, pageSize);
            setUsersData(items);
            setFilteredUsersData(items);
            setTotalItems(pagination.total);
        } else {
            const { gridItems, formItems, pagination } = await fetchGroupsPaginated(currentPage, pageSize);
            setGroupsGridData(gridItems);
            setFilteredGroupsGridData(gridItems);
            setGroupsFormData(formItems);
            setTotalItems(pagination.total);
        }
        setIsLoading(false);
    }, [selectedTabIndex, currentPage, pageSize]);

    useEffect(() => {
        loadUsersGroups();
    }, [currentPage, pageSize, selectedTabIndex, loadUsersGroups]);

    const onPageChange = (page) => {
        if (page >= 1 && page <= Math.ceil(totalItems / pageSize)) {
            setCurrentPage(page);
        }
    };

    const onPageSizeChange = (newSize) => {
        setPageSize(newSize);
        setCurrentPage(1);
    };

    const addTab = () => {
        const newTabId = tabs.length + 1;
        const isUserTabActive = selectedTabIndex === 0 || tabs[selectedTabIndex].title.includes('Usuário');
        const newTabTitle = isUserTabActive ? `Usuário #${newTabId}` : `Grupo #${newTabId}`;
        setTabs([...tabs, { id: newTabId, title: newTabTitle }]);
        setSelectedTabIndex(tabs.length);
    };

    const removeTab = (index) => {
        const updatedTabs = tabs.filter((_, tabIndex) => tabIndex !== index);
        setTabs(updatedTabs);
        setSelectedTabIndex(index === selectedTabIndex ? 0 : selectedTabIndex > index ? selectedTabIndex - 1 : selectedTabIndex);
    };

    const editRow = (row) => {
        const newTabId = tabs.length + 1;
        const entityType = selectedTabIndex === 0 ? 'Usuário' : 'Grupo';
        const newTabTitle = row.ID ? `Editar ${entityType} #${row.ID}` : `Editar ${entityType}`;
        const newTab = {
            id: newTabId,
            title: newTabTitle,
            formData: row,
        };
        setTabs([...tabs, newTab]);
        setSelectedTabIndex(tabs.length);
    };

    const handleSave = () => {
        console.log('Saving data...');
    };

    const deleteRow = () => {
        console.log('Deleting data...');
    };

    const refreshData = () => {
        loadUsersGroups();
    };

    const headersUsers = ['ID', 'Nome', 'Sobrenome', 'Email', 'Instituição', 'Grupos', 'Ações'];
    const headersGroups = ['ID', 'Nome', 'Carregar', 'Camadas', 'Usuários', 'Serviços', 'Logs e Acessos', 'Ações'];

    return (
        <section className="gerUsuario">
            <Head />
            <div className="main-container">
                <LateralBar />
                <div className='right-container'>
                    {selectedTabIndex === 0 ? (
                        <TBUser
                            addTab={addTab}
                            showSecondaryButtons={false}
                            currentPage={currentPage}
                            totalItems={totalItems}
                            pageSize={pageSize}
                            onPageChange={onPageChange}
                            onPageSizeChange={onPageSizeChange}
                            onSave={handleSave}
                            onCancel={() => removeTab(selectedTabIndex)}
                            onDelete={deleteRow}
                            onRefresh={refreshData}
                            onFilter={setFilteredUsersData}
                        />
                    ) : (
                        <TBGroup
                            addTab={addTab}
                            showSecondaryButtons={false}
                            currentPage={currentPage}
                            totalItems={totalItems}
                            pageSize={pageSize}
                            onPageChange={onPageChange}
                            onPageSizeChange={onPageSizeChange}
                            onSave={handleSave}
                            onCancel={() => removeTab(selectedTabIndex)}
                            onDelete={deleteRow}
                            onRefresh={refreshData}
                            onFilter={setFilteredGroupsGridData}
                        />
                    )}
                    <Tabs selectedIndex={selectedTabIndex} onSelect={index => setSelectedTabIndex(index)}>
                        <TabList>
                            {tabs.map((tab, index) => (
                                <Tab key={index}>
                                    {tab.title}
                                    {index !== 0 && index !== 1 && (
                                        <button onClick={(e) => { e.stopPropagation(); removeTab(index); }}>&#x2715;</button>
                                    )}
                                </Tab>
                            ))}
                        </TabList>
                        {tabs.map((tab, index) => (
                            <TabPanel key={index}>
                                <div className="tab-content">
                                    {isLoading ? (
                                        <div className="logo-loading"></div>
                                    ) : tab.title === tab1 ? (
                                        <Grid headers={headersUsers} data={filteredUsersData} area="GerUsuario" editRow={editRow} loadData={loadUsersGroups} />
                                    ) : tab.title === tab2 ? (
                                        <Grid headers={headersGroups} data={filteredGroupsGridData} area="GerGrupos" editRow={editRow} loadData={loadUsersGroups} />
                                    ) : tab.title.includes('Usuário') ? (
                                        <FormTabUser formData={tab.formData} initialID={tab.formData?.ID} />
                                    ) : (
                                        <FormTabGroup formData={tab.formData} initialID={tab.formData?.ID} />
                                    )}
                                </div>
                            </TabPanel>
                        ))}
                    </Tabs>
                </div>
            </div>
        </section>
    );
}

export default GerUsuario;