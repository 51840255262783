import { Link } from 'react-router-dom';
import Head from '../../components/Head';

function Erro() {
    return (
        <section className="erro">
            <Head />
            <div className="home-content">
                <p>
                    Parece que essa página não existe!<br />
                    <Link to="/" className="btn_voltar">&#8592;&nbsp;Voltar para a Home</Link>
                </p>
            </div>
        </section>
    )
}

export default Erro;