import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './lateral.css';
import logo from '../../img/logo/logo-sisema.png';
import { IcoCarCamadaSmall, IcoCadMapaSmall, IcoConfigCamadaSmall, IcoGerUsuarioSmall, IcoConfigServiceSmall, IcoLogAcessoSmall } from '../../img/ico/icoSmall'

const LateralBar = () => {
    const icoGreen = '#517147';
    const icoGray = '#C0C0C0';
    const location = useLocation();
    const [activePath, setActivePath] = useState(location.pathname);

    const handleLinkClick = (path) => {
        setActivePath(path);
    };

    return (
        <div className="sidebar">
            <div className="icon-container">
                <Link to="/carCamada" className='icon-wrapper' onClick={() => handleLinkClick('/carCamada')}>
                    <IcoCarCamadaSmall color={activePath === '/carCamada' ? icoGreen : icoGray} />
                </Link>
                <Link to="/cadMapa" className='icon-wrapper' onClick={() => handleLinkClick('/cadMapa')}>
                    <IcoCadMapaSmall color={activePath === '/cadMapa' ? icoGreen : icoGray} />
                </Link>
                <Link to="/configCamada" className='icon-wrapper' onClick={() => handleLinkClick('/configCamada')}>
                    <IcoConfigCamadaSmall color={activePath === '/configCamada' ? icoGreen : icoGray} />
                </Link>
                <Link to="/gerUsuario" className='icon-wrapper' onClick={() => handleLinkClick('/gerUsuario')}>
                    <IcoGerUsuarioSmall color={activePath === '/gerUsuario' ? icoGreen : icoGray} />
                </Link>
                <Link to="/configService" className='icon-wrapper' onClick={() => handleLinkClick('/configService')}>
                    <IcoConfigServiceSmall color={activePath === '/configService' ? icoGreen : icoGray} />
                </Link>
                <Link to="/logAcesso" className='icon-wrapper' onClick={() => handleLinkClick('/logAcesso')}>
                    <IcoLogAcessoSmall color={activePath === '/logAcesso' ? icoGreen : icoGray} />
                </Link>
            </div>
            <div className="logo-container">
                <Link to="/">
                    <img src={logo} alt="Sisema Logo" className="logo" />
                </Link>
            </div>
        </div>
    );
};

export default LateralBar;