import '../../components/style.css';
import Head from '../../components/Head';
import LateralBar from '../../components/LateralBar';

function LogAcesso() {
    let topBar = (
        <div className='top-bar'>
            &nbsp;
        </div>
    );

    return (
        <section className="carCamada">
            <Head />
            <div className="main-container">
                <LateralBar />
                <div className='right-container'>
                    {topBar}
                    <div className='inner-section'>
                        Logs e Acesesos
                    </div>
                </div>
            </div>
        </section>
    );
}

export default LogAcesso;