import React, { useState, useEffect, useCallback } from 'react';
import './login.css';
import { validateAuth, loggedUserInfo } from '../../components/Connector';

const API_URL = 'https://homologa.meioambiente.mg.gov.br/idesisema/backend/api/auth/login';
const TOKEN_EXPIRY_TIME = 24 * 60 * 60 * 1000;

const Login = ({ isVisible, onClose, onLoginSuccess, onLogout }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const handleLogout = useCallback(() => {
        setIsLoggedIn(false);
        onLogout();
    }, [onLogout]);

    const renewToken = useCallback(async () => {
        const token = localStorage.getItem('authToken');
        if (!token) return;

        try {
            const response = await fetch(`${API_URL}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });

            if (response.ok) {
                const data = await response.json();
                localStorage.setItem('authToken', data.access_token);
                localStorage.setItem('tokenExpiry', Date.now() + TOKEN_EXPIRY_TIME);
                console.log('Token renovado');
            } else {
                throw new Error('Falha na renovação do token');
            }
        } catch (error) {
            console.error('Falha na renovação do token:', error);
            handleLogout();
        }
    }, [handleLogout]);

    const startTokenRenewal = useCallback(() => {
        setInterval(() => {
            const tokenExpiry = localStorage.getItem('tokenExpiry');
            if (tokenExpiry && Date.now() >= tokenExpiry) {
                renewToken();
            }
        }, 10 * 60 * 1000);
    }, [renewToken]);

    useEffect(() => {
        const checkAuth = async () => {
            const isAuthenticated = await validateAuth();
            setIsLoggedIn(isAuthenticated);
            if (isAuthenticated) {
                startTokenRenewal();
            }
        };

        checkAuth();
    }, [startTokenRenewal]);

    const login = async (email, password) => {
        try {
            const response = await fetch(`${API_URL}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, password })
            });

            if (response.ok) {
                const data = await response.json();
                localStorage.setItem('authToken', data.access_token);
                localStorage.setItem('tokenExpiry', Date.now() + TOKEN_EXPIRY_TIME);
                setIsLoggedIn(true);
                setErrorMessage('');
                onLoginSuccess();
                startTokenRenewal();
                console.log('Informações do usuário após login:', loggedUserInfo);
            } else {
                throw new Error('Falha no login');
            }
        } catch (error) {
            console.error('Falha no login:', error);
            setErrorMessage('Falha no Login. Por favor verifique suas credenciais.');
        }
    };

    const handleLogin = async () => {
        await login(username, password);
        onClose();
    };

    const handleCancel = () => {
        setUsername('');
        setPassword('');
        setErrorMessage('');
        onClose();
    };

    if (!isVisible) return null;

    return (
        <div className="login-overlay">
            <div className="login-container">
                <div className="input-group">
                    <label>Usuário</label>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Digite seu usuário"
                    />
                </div>
                <div className="input-group">
                    <label>Senha</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Digite sua senha"
                    />
                </div>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                <div className="button-group">
                    {isLoggedIn ? (
                        <button onClick={handleLogout}>Sair</button>
                    ) : (
                        <button onClick={handleLogin}>Entrar</button>
                    )}
                    <button onClick={handleCancel}>Cancelar</button>
                </div>
            </div>
        </div>
    );
};

export default Login;